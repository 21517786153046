<template>
	<div class="content">
		
		<div>
			<div v-loading="titleLoading" class="gen-title">
				<div class="import-titles-button">
					<el-input size="small" rows="4" style="width: 330px;margin-top:2rem" type="textarea" v-model="userTitles" placeholder="自定义标题, 一行一个" @click="genTitles">生成10个标题</el-input>
					<el-button size="small" type='primary' style="width: 5rem;margin-top:.5rem" @click="importTitles">导入标题</el-button>
				</div>
				
				<div style="margin-top:3rem;margin-bottom:2rem">
					<el-input style="width: 250px" size="small" v-model="titleKeywords" placeholder="请输入一个主题" el-input></el-input>
					<el-button size="small" type="primary" style="margin:0" @click="genTitles">生成10个标题</el-button>
				</div>
			</div>
			<div>
				<div>
					<el-checkbox-group v-model="titlesSelect" class="titleList" :key="componentKey">
						<el-checkbox class="title-item" v-for="title in titles" :label="title" :key="title">{{title}}<i class="el-icon-close" @click.stop="delTitle(title)"></i></el-checkbox>
					</el-checkbox-group>
				</div>
			</div>
			
			<div class="task-input" v-show="titlesSelect.length > 0">
				<p>配置自动发布队列</p>
				<div class="task-time">
					<div class="task-day-box">
						<div class="task-day">
							<div class="task-day-one block">
								<span>从</span>
								<el-date-picker
									v-model="task.startDate"
									type="date"
									@change="hourChange"
									placeholder="固定时间"
								/>
								<span>起,</span>
							</div>
							
							<div class="task-day-one">
								<span>每</span>
								<el-input v-model="task.dayCount" @blur="fixCount"></el-input>
								<span>天,</span>
							</div>
							<div class="task-day-one">
								<span>发布</span>
								<el-input v-model="task.articleCount" @blur="fixCount"></el-input>
								<span>篇</span>
							</div>
						</div>
						<div v-if="task.articleCount > 1 && task.dayCount > 1" class="date-type">
							<el-radio-group v-model="task.dateType">
								<el-radio label="evenly">等差日期</el-radio>
								<el-radio label="random">随机日期</el-radio>
							</el-radio-group>
						</div>
					</div>
					
					<div class="hour-config">
						<p>当天的发布时间:</p>
						<el-radio-group v-model="task.hourType">
							<el-radio label="random">随机时间</el-radio>
							<el-radio label="fixed">固定时间</el-radio>
							<el-radio label="randomRange">范围内随机</el-radio>
						</el-radio-group>
						
						<div class="time-picker">
							<div v-show="task.hourType === 'fixed'">
								<el-time-picker
									v-model="task.fixedHour"
									type="dates"
									range-separator="至"
									value-format="HH:mm:ss"
									@change="hourChange"
									placeholder="固定时间"
									style="width: 170px;padding: 0 10px;"
								/>
							</div>
							<div v-show="task.hourType==='randomRange'">
								<el-time-picker
									v-model="task.randomHourBegin"
									type="dates"
									range-separator="至"
									value-format="HH:mm:ss"
									@change="hourChange"
									placeholder="随即最早"
									style="width: 130px;padding: 0 10px;"
								/>-
								<el-time-picker
									v-model="task.randomHourEnd"
									type="dates"
									range-separator="至"
									value-format="HH:mm:ss"
									@change="hourChange"
									placeholder="随机最晚"
									style="width: 130px;padding: 0 10px;"
								/>
							</div>
						</div>
					</div>
				</div>
				
			</div>
		</div>
		
		
		<div v-show="titlesSelect.length > 0" class="genBox">
      <div class="switch">
        <span>自动生成图片: </span>
        <span v-show="!autoGenImg">已关</span>
        <span v-show="autoGenImg">已开</span>
        <el-switch
          v-model="autoGenImg"
          active-color="#13ce66"
          inactive-color="#ff4949"
          @change="switchGenImg"
        />
      </div>

			<div class="result">
				
				<div v-for="article in articleList" class="article-item" :key="article._id">
					<div class="release-time">
						<span class="auto-time">自动发布: {{article.releaseTimeShow}}</span>
						<span class="title">{{article.title}}</span>
            <span style="margin-left: 1rem;">
              <el-button size="mini" style="width: 4rem;white-space:normal;padding:0.2rem" @click="selectLocalImg(article.title)">增加<br>本地素材</el-button>
            </span>
            <span>
              <img v-for="img in selectLocalImgList[titlesSelect.indexOf(article.title)]" :key="img" :src="img" style="width: 3rem;height: 3rem;margin-left: .5rem;border-radius: .3rem"/>
            </span>
            <div>
              <driver-select style="margin-left:20px" @change="driverChange" :keyIndex="articleList.indexOf(article)"></driver-select>
            </div>
					</div>
				</div>
			</div>
			
			<div class="genAll">
				<el-button size="medium" type="primary" @click="genAll()">一键生成文章</el-button>
			</div>
		</div>

    <div>
      <el-dialog
        class="img-list-view"
        :visible.sync="imgListVisible"
        :fullscreen="true"
        @close="imgListClose"
        :close-on-click-modal="false">
        <ImgListView :isSelectMode="true" ref="imgView" @selectDone="selectDone"/>
      </el-dialog>
    </div>
		
	</div>
</template>

<script>

import ImgListView from "@/page/client/material/list.vue";
import DriverSelect from "@/components/driverSelect.vue";
import {isVideo} from "@/utils/utils";

export default {
	data() {
		return {
			titles:[],
      autoGenImg: false,

			titlesSelect:[],
			titleKeywords: "",
			titleLoading: false,
			userTitles:'',
      componentKey: 0,
      imgListVisible: false,
      selectLocalImgIndex: -1,
      selectLocalImgList: [[]],
      driverList:[[]],
			imgForm:{
				keywords: '',
				en_keywords: '',
			},
			imgLoading: false,
			textLoading: false,
			
			articleList:[],
			result: {
				title: "",
				imgUrl: process.env.VUE_APP_BASE_API + "/upload/default.png",
				imgUrlOrigin:"",
				text:"",
			},
			
			task: {
				startDate: "",
				dayCount: 1,
				articleCount: 1,
				dateType: "evenly",
				
				hourType: 'random',
				fixedHour:"",
				randomHourBegin:"",
				randomHourEnd:""
			},
		};
	},
	
	components: {DriverSelect, ImgListView},
	
	computed: {},
	
	mounted() {
		//获取当前时间复制给startDate
		this.task.startDate = new Date()
		this.task.fixedHour = new Date()
		
		//当天开始时间
		this.task.randomHourBegin = '00:00:00';
		this.task.randomHourEnd = '23:59:59'
	},
	
	watch: {
		
		async titlesSelect(val) {
			this.articleList = []
			await val.forEach((item, index) => {
				this.articleList.push({
					title: item,
					imgUrl: '',
					imgUrlOrigin: "",
					text: '',
				})
			})
			this.rebaseReleaseTime()
		},
		task: {
			deep: true,
			handler(newVal, oldVal) {
				if (this.task.dayCount <= 0 || this.task.dayCount === '' || this.task.articleCount <= 0 || this.task.articleCount === '') {
					return;
				}
				if (this.task.articleCount > this.articleList.length && this.articleList.length > 0) {
					this.task.articleCount = this.articleList.length
					return;
				}
				this.rebaseReleaseTime()
			}
		}
	},
	
	methods: {
		importTitles() {
			this.userTitles.split("\n").forEach((item, index) => {
				this.titles.push(item)
				this.titlesSelect.push(item)
			})
		},
    driverChange(driverList, index) {
      this.driverList[index] = driverList
    },
		async genAll() {
      //标题不能为空
      if (this.titlesSelect.length === 0) {
        this.$message.error('请至少选择一个标题')
        return
      }

      for (const titleOne of this.titlesSelect) {
        let index = this.titlesSelect.indexOf(titleOne)
        if (this.selectLocalImgList[index] === undefined) {
          this.selectLocalImgList[index] = []
        }
        if (!this.autoGenImg) {
          if (this.selectLocalImgList[index].length === 0) {
            this.$message.error('请至少选择一个素材: ' + titleOne)
            return
          }
        }
        console.log(this.driverList, index)
        if (!this.driverList || this.driverList[index].length === 0) {
          this.$message.error('请至少选择一个账户: ' + titleOne)
          return
        }
      }

      //弹窗输入字数,默认400
      this.$prompt('请输入每篇文章的字数', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: 400,
        inputPattern: /\d+/,
        inputErrorMessage: '请输入数字'
      }).then(async (wordCount) => {

        let titles = this.articleList.map((item, index) => {
          return this.articleList[index].title
        }).join(',')
        let releaseTimes = this.articleList.map((item, index) => {
          //转为Y-m-d H:i:s
          return parseInt(parseInt(this.articleList[index].releaseTime.getTime()) / 1000)
        }).join(',')

        let selectLocalImgListPost = {}
        let arrayIndex = 0
        for (const imgList of this.selectLocalImgList) {
          selectLocalImgListPost[arrayIndex] = []
          for (let item of imgList) {
            if (item.indexOf('http') !== 0) {
              continue
            }
            const index = imgList.indexOf(item);
            //反斜杠转为正斜杠
            item = item.replace(/\\/g, '/')
            let port = item.match(/http:\/\/localhost:(\d{4})/)[1];
            let tail = item.split(':').slice(2).join('').split('/').slice(1).join('/')
            await pywebview.api.getInputPath(port).then(res => {
              selectLocalImgListPost[arrayIndex] = selectLocalImgListPost[arrayIndex] || {}
              selectLocalImgListPost[arrayIndex][index] = res + ',/' + tail
            })
          }
          ++arrayIndex
        }

        console.log(titles, releaseTimes, wordCount, this.driverList)
        this.$http.post('/client/gen/batchGenArticles', {
          titles: titles,
          releaseTimes: releaseTimes,
          selectLocalImgList: selectLocalImgListPost,
          wordCount: wordCount.value,
          autoGenImg: this.autoGenImg,
          driverList: this.driverList,
        }).then(res => {

          this.$message.success('生成成功');

          for (const imgList in selectLocalImgListPost) {
            let break1 = false
            for (const item in selectLocalImgListPost[imgList]) {
              if (isVideo(selectLocalImgListPost[imgList][item])) {
                this.$message.success('视频素材不会自动生成文字和图片')
                break1 = true
                break
              }
            }
            if (break1) {
              break
            }
          }

          this.$router.push({path: "/generateList/generateList"}).then(() => {
            // 获取指定路由对象
            try {
              let matchedRoute = this.$route.matched.find(route => route.path === '/generateList/generateList');
              matchedRoute.instances.default.$refs.normalTable.getList()
            } catch (e) {
              console.log(e)
            }
          });
        })

      }).catch((e) => {
        console.log(e)
        this.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
		
		hourChange(val) {
			console.log(val)
		},
		fixCount() {
			if (this.task.dayCount <= 0 || this.task.dayCount === '') {
				this.task.dayCount = 1
			}
			if (this.task.articleCount <= 0 || this.task.articleCount === '') {
				this.task.articleCount = 1
			}
		},
		rebaseReleaseTime() {
			
			let articleCount = this.titlesSelect.length
			
			//先处理日期
			let dateList = []
			if (this.task.dateType === 'evenly') {
				for (let i = 0; i < articleCount; i++) {
					let date = new Date(this.task.startDate)
					date.setDate(date.getDate() + dateList.length * this.task.dayCount/this.task.articleCount)
					dateList.push(new Date(date))
				}
			} else if (this.task.dateType === 'random') {
				for (let i = 0; i < articleCount; i++) {
					let date = new Date(this.task.startDate)
					//1到 this.task.dayCount 之间随机
					let randomDay = Math.floor(Math.random() * this.task.dayCount) + 1
					date.setDate(date.getDate() + (dateList.length * randomDay))
					dateList.push(new Date(date))
				}
			}
			
			//再处理小时的时间
			if (this.task.hourType === 'fixed') {
				if (!this.task.fixedHour) {
					this.$message({
						message: "请选择固定时间",
						type: 'error'
					});
					return;
				}
				
				dateList.forEach((date, index) => {
					//小时,分钟 部分换成固定时间
					let fixedHour = this.task.fixedHour
					date.setHours(fixedHour.getHours())
					date.setMinutes(fixedHour.getMinutes())
				})
				
			} else if (this.task.hourType === 'randomRange') {
				if (!this.task.randomHourBegin || !this.task.randomHourEnd) {
					this.$message({
						message: "请选择随机时间范围",
						type: 'error'
					});
					return;
				}
				dateList.forEach((date, index) => {
					//小时,分钟 部分换成随机时间
					let randomHour = Math.floor(Math.random() * (this.task.randomHourEnd.split(":")[0] - this.task.randomHourBegin.split(":")[0])) + parseInt(this.task.randomHourBegin.split(":")[0])
					date.setHours(parseInt(randomHour))
					//0-60分钟随机
					let randomMinute = Math.floor(Math.random() * 60)
					date.setMinutes(randomMinute)
				})
				
			} else if (this.task.hourType === 'random') {
				if (!this.task.randomHourBegin || !this.task.randomHourEnd) {
					this.$message({
						message: "请选择随机时间",
						type: 'error'
					});
					return;
				}
				dateList.forEach((date, index) => {
					//小时,分钟 部分换成随机时间
					let randomHour = Math.floor(Math.random() * 24)
					let randomMinute = Math.floor(Math.random() * 60)
					date.setHours(randomHour)
					date.setMinutes(randomMinute)
				})
			}
			
			//dataList升序排序
			dateList.sort((a, b) => {
				return a.getTime() - b.getTime()
			})
			
			//把时间复制给articleList
			dateList.forEach((date, index) => {
				this.articleList[index].releaseTime = date
				//只展示 月分 日期,时间
				this.articleList[index].releaseTimeShow = date.getMonth() + 1 + '月' + date.getDate() + '日 ' + date.getHours() + ':' + date.getMinutes()
				
			})
			this.$forceUpdate()
		},
		genTitles() {
			if (this.titleKeywords === '') {
				this.$message({
					message: "请输入标题的表述",
				});
				return
			}
			this.titleLoading = true
			this.$http.post('/client/gen/genTitles', {keywords: this.titleKeywords}).then(res => {
				res.data.titles.forEach((item, index) => {
					 this.titles.push(item)
				})
			}).finally(() => {
				this.titleLoading = false
			})
		},
    delTitle(title) {
      this.titlesSelect = this.titlesSelect.filter(item => item !== title);
      this.titles = this.titles.filter(item => item !== title);
      this.componentKey++
    },
    switchGenImg() {
      // this.autoGenImg = !this.autoGenImg
    },
    selectLocalImg(title) {
      this.selectLocalImgIndex = this.titlesSelect.indexOf(title)
      this.imgListVisible = true
      if (this.$refs.imgView !== undefined) this.$refs.imgView.selectImgList = []
    },
    imgListClose() {
      // 这里是你的处理逻辑
      console.log('关闭', this.imgListVisible);
    },
    selectDone(imgList) {
      this.selectLocalImgList[this.selectLocalImgIndex] = imgList
      this.imgListVisible = false
    }
	},
};
</script>
<style lang="scss" scoped>
.content {
	margin-top: 1rem;
	.import-titles-button {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		align-items: center;
		margin-top: 1rem;
	}
	.gen-title {
		margin-top:2rem;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		::v-deep .el-input {
			text-align: center;
			max-width: 20rem;
		}
		button {
			margin-top: 1rem
		}
		.titleList {
			width: 100%;
			display: flex;
			justify-content: space-around;
			.title-item {
				width: 50%;
			}
		}
	}
	.result {
		display: flex;
		justify-content: space-around;
		align-items: center;
		flex-wrap: wrap;
		border: solid 1px #ebeef5;
		border-radius: 10px;
		.article-item:not(:last-child) {
			border-bottom: solid 1px #ebeef5;
		}
		.article-item {
			padding: 1rem;
			width: 100%;
			.release-time {
				display: flex;
				justify-items: center;
				align-items: center;
				
				.auto-time {
					display: inline-block;
					width: 25%;
					text-align: right;
					border-right: solid 1px #ebeef5;
					padding-right: 0.5rem;
				}
				.title {
					font-size: 0.8rem;
					padding-left: 1rem;
				}
			}
		}
	}
	
	.article-content {
		width: 100%;
	}
	.genBox {
		margin-top: 1rem;
		display: flex;
		justify-content: center;
		flex-direction: column;
	}
	.task-input {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		font-size: 14px;
		font-weight: 900;
		border: 1px solid #ebeef5;
		border-radius: 10px;
		margin-top:1.5rem;
		padding-bottom: 1rem;
		>p{
			line-height: 2.5rem;
			font-size: 17px;
		}
		
		.task-time {
			display: flex;
			flex-wrap: wrap;
			width: 100%;
			justify-content: space-around;
			.task-day-box {
				border: solid 1px #ebeef5;
				border-radius: 10px;
				display: flex;
				align-items: center;
				flex-direction: column;
				justify-content: center;
				margin: 0.5rem 0;
				.date-type {
					.el-radio-group {
						display: flex;
						justify-content: center;
						align-items: center;
						.el-radio {
							margin-right: 10px;
						}
						::v-deep .el-radio__label {
							padding-left:4px;
						}
					}
				}
			}
			.task-day-box:hover {
				box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
			}
			.task-day {
				flex-wrap: nowrap;
				padding: 0.5rem 1rem 0.5rem 1rem;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-right: 1rem;
				.task-day-one {
					>span {
						display: inline;
					}
					.el-input {
						display: inline;
					}
				}
				.block {
					.el-input {
						display: inline-block !important;
						width: 4.4rem;
						::v-deep .el-icon-date {
							display: none;
						}
						::v-deep .el-input__icon {
							display: none;
						}
						::v-deep .el-input__inner {
							width: 4.4rem !important;
							font-size: 10px;
						}
					}
				}
				::v-deep .el-input__inner {
					width: 2rem;
					padding-left: 0.5rem;
					padding-right: 0.5rem;
					display: inline;
				}
			}
			.hour-config {
				border: solid 1px #ebeef5;
				border-radius: 10px;
				padding: 1rem;
				.time-picker {
					display: flex;
					justify-content: center;
					margin-top:1rem
				}
			}
			.hour-config:hover {
				box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
			}
		}
	}
}
.genAll {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 2rem;
	margin-bottom: 5rem;
}
.import-titles {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}
.el-icon-close {
  color: black;
  margin-left: 10px;
}
.el-icon-close:hover {
  color: red;
}
.switch {
  font-size: 15px;
  margin: 0 auto 10px;
}
</style>
<style>
 .el-message-box {
	 max-width: 100% !important;
 }
</style>