<template>
  <div class="driver-list">
    <div>
      <div>
        <el-form ref="form"  style="margin-right: 1rem; margin-top:1rem;text-align: left">
          <el-form-item >
            <el-form-item label="账户分组: " label-width="100px">
              <el-checkbox-group v-model="tagList" style="margin-left:20px">
                <el-checkbox v-for="tag in allTagList" :label="tag" :key="tag">{{tag}}</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="platform-list"  style="text-align: left;padding-left: 2.5rem;margin-top:-2rem">
      <div class="platform-one" v-for="platform in platformList" :key="platform.platformName">
        <h3>{{ platform.platformName }}</h3>
        <el-checkbox-group v-model="selectDriver">
          <el-checkbox  v-for="item in driverList[platform.platform]" :key="item._id" :label="item._id">{{ item.account }}/{{item.remark}}</el-checkbox>
        </el-checkbox-group>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "driverSelect",
  props: {
    iconClass: {
      type: String,
      default: ""
    },
    keyIndex: {
      type: Number,
      defualt: 0
    },
    selectDriverIn: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectDriver: [],
      driverList:{},
      platformList:[],
      tagList:[],
      allTagList:[],
      allDriver:[],
    }
  },
  watch: {
    tagList() {
      this.selectDriver = []

      this.allDriver.forEach((item) => {
        if (item.tagList?.length > 0) {
          let flag = false
          item.tagList.forEach((tag) => {
            if (this.tagList.includes(tag)) {
              flag = true
            }
          })
          if (flag) {
            this.selectDriver.push(item._id)
          }
        }
      })
    },
    selectDriver() {
      this.$emit('change', this.selectDriver, this.keyIndex)
    },
    selectDriverIn() {
      this.selectDriver = this.selectDriverIn
    }
  },
  created() {
    this.selectDriver = this.selectDriverIn
  },
  computed: {},
  mounted() {
    this.initDriver()
    this.getAllTag()
  },
  methods: {
    iconName() {
      return `#icon-${this.iconClass}`;
    },
    svgClass() {
      return this.className ? `svg-icon ${this.className}` : "svg-icon";
    },
    initDriver() {
      this.$http.post("/client/driver/getDriverList", {pageNum:0, pageSize:1000, status:'active'}).then((res) => {
        res.data.list.forEach((item) => {
          if (!item) {
            return
          }
          if ((typeof this.driverList[item.platform]) === 'undefined') {

            this.driverList[item.platform] = []
            this.selectDriver[item.platform] = []
            this.platformList.push({'platformName':item.platformName, 'platform':item.platform})
          }
          this.driverList[item.platform].push(item)
        })
        this.allDriver = res.data.list
        //如果只有一个, 自动选择
        if (this.allDriver.length === 1) {
          this.selectDriver.push(this.allDriver[0]._id)
        }
      })
    },
    getAllTag() {
      return this.$http.post('/client/driver/getAllTag', {}).then(res => {
        this.allTagList = res.data
        if (this.allTagList.indexOf('默认分组') === -1) {
          this.allTagList.push('默认分组')
        }
      })
    },
  }
};
</script>
<style scoped>
.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.driver-list {
  border: solid 1px #eee;
  border-radius: 5px;
  padding-bottom: 1rem;
  .platform-one {
    text-align: left;
    margin-top:10px;
  }
}
</style>